<template>
    <div>
        <div class="title_con">产品简介</div>
        <div class="con_prod">
            <div class="item_prod">
                <h3 class="title_item_prod">HD8916双边糊底机</h3>
                <div class="decri_prod">糊制两层至四层纸袋，可制作普通单层阀口纸袋、筒状外置阀口纸袋、大底小阀口纸袋、超声波阀口纸袋等。生产速度最高可达100条/分钟

                </div>
                <el-button class="btn_more" @click="checkDetail('hd8916')">查看详情</el-button>
                <img class="img_prod" src="@/assets/trans_bottomer.png">
            </div>
            <div class="item_prod">
              <h3 class="title_item_prod">ZT1962S伺服制筒机</h3>
                <div class="decri_prod">可制造纸筒、缝纫袋何阀口袋。核心工位由伺服系统控制，可灵活快速变换规格生产，生产速度最高达150条/分钟
                </div>
                <el-button class="btn_more" @click="checkDetail('zt1962s')">查看详情</el-button>
                <img class="img_prod" src="@/assets/machine/ZT1962S.png">
            </div>
            <div class="item_prod">
              <h3 class="title_item_prod">SAY1300C4型木纹纸凹版印刷机</h3>
                <div class="decri_prod">国内一流装饰纸印刷机，双工位自动接换料，触摸屏集中控制，可选导热油、蒸汽、电、天然气多种加热方式，印刷速度300米/分钟
                </div>
                <el-button class="btn_more" @click="checkDetail('say1300c4')">查看详情</el-button>
                <img class="img_prod" src="@/assets/machine/SAY1300C4.png">
            </div>
            <div class="item_prod">
              <h3 class="title_item_prod">多类型纸袋</h3>
                <div class="decri_prod">适用于建材、化工行业的多种密封方式的阀口纸袋包装纸袋，包括超声阀、热熔阀、纸夹膜错位阀等</div>
                <el-button class="btn_more" @click="checkBagLis">查看详情</el-button>
                <img class="img_prod" src="@/assets/paperbag.png">
            </div>
        </div>
    </div>
</template>
<script>
    import { ElButton} from 'element-plus'
export default {
  components: {
    ElButton,
  },
  methods:{
    checkDetail(name){
      this.$router.push('/prod/'+name);
      document.documentElement.scrollTop=0
    },
    checkBagLis(){
      this.$router.push('productmenu');
      document.documentElement.scrollTop=1500
    }
  }

}
</script>
<style scoped>
.title_item_prod{
  margin-top: 50px;
  text-align: center;
  color: white;
}

.decri_prod{
  text-indent: 2em;
  height: 120px;
  padding: 20px;
  color: white;
  font-size: 15px;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.img_prod{
  width: 100%;
  position: absolute;
  left:0;
  bottom: 30px;
}
.con_prod {
  height: 550px;
  display: flex;
  padding: 0 200px;
  justify-content: space-between;
}
.item_prod {
  height: 500px;
  width: 22%;
  background-image: linear-gradient(#159c95, #4ebeb0);
  box-shadow: 2px 2px 6px 2px #959595;
  position: relative;
}
.btn_more{
    background-color: #2f50ff;
    color: white;
    border:#2550ff;
    font-weight: 500;
    margin-left: 55%;
}
@media screen and (max-width:600px) {
  .con_prod{
    display: block;
    height: auto;
    padding: 0;
    justify-content: unset;
  }
  .item_prod{
    width: 100%;

  }

  .title_item_prod{
    font-size: x-large;
    margin: 0;
    line-height: 50px;
    padding-top: 20px;
  }
  .btn_more{
    float:right;
    margin-right: 20px;
  }
}


.item_prod:hover {}
.title_con {
  text-align: center;
  font-size: xx-large;
  font-weight: 550;
  line-height: 130px;
}


</style>
<template>
    <div class="con_news">
        <div style="width: 70%;">
            <div class="title" @click="jumptToNews()">最新消息<text style="color:#2f50ff"> > > ></text></div>
            <div class="br_line"></div>
            <div style="text-align:center;color:gray;line-height:70px">暂无消息</div>
            <!-- <div class="item_news">
                <div class="title_item">April,5,2022</div>|
                <div class="cont_item">
                    中午，一辆货车徐徐驶进佛山市达肯包装机械公司的厂区，工人们熟练地将货车上5000多条客户的袋筒卸下来整齐地摆放在一条崭新的糊底机的机头位置。 该客户从别的供应商那里购买了一整条阀口袋生产线。客户订单挺多，但工厂是刚建立没多久...
                </div>
            </div> -->
         
            <!-- <el-button class="btn_more">查看更多</el-button> -->

        </div>
    </div>
</template>
<script>
    // import { ElButton} from 'element-plus'
    export default{
        components:{
            // ElButton
        },
        methods:{
            jumptToNews(){
                this.$router.push('news')
                document.documentElement.scrollTop=0
            }
        }
    }
</script>
<style scoped>
.con_news {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    padding-bottom: 40px;
    padding-top: 20px;
    min-height: 200px;
}

.title {
    font-size: large;
    line-height: 50px;
    font-weight: 550;
    padding-left: 20px;
}
.title:hover{
    text-decoration: underline;
    cursor: pointer;
}
.br_line {
    height: 2px;
    width: 100%;
    background-color: #e7e7e7;
}

.item_news {
    display: flex;
    /* height: 60px; */
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    color: #e7e7e7;
}

.title_item {
    text-align: center;
    width: 140px;
    color: #333;
}

.cont_item {
    margin: 18px;
    /* line-height: normal; */
    color: #333;
    width: 100%;
    /* word-wrap: break-word; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: auto;
    max-height: 40px;
    box-sizing: border-box;
    text-indent: 2rem;
    cursor: pointer;
}

.cont_item:hover{
    text-decoration: underline;
}

.btn_more{
    background-color: #2f50ff;
    color: white;
    border:#2550ff;
    font-weight: 500;
    margin-left: 150px;
    float: right;
    margin-top: 20px;
}
</style>
<template>
  <nav class="nav1">
    <img src="@/assets/tans_logo.png" class="img_logo">
    <router-link to="/">
      <div>
        首页
        <div class="underline_hover"></div>
      </div>
    </router-link>
    <router-link to="/productmenu">

      <div>产品介绍<div class="underline_hover"></div>
      </div>
    </router-link>
    <router-link to="/service">

      <div>服务<div class="underline_hover"></div>
      </div>
    </router-link>
    <router-link to="/aboutus">

      <div>关于我们<div class="underline_hover"></div>
      </div>
    </router-link>
    <router-link to="/news">

      <div>新闻<div class="underline_hover"></div>
      </div>
    </router-link>
    <!-- <router-link >新闻</router-link>
    <router-link >关于我们</router-link> -->
  </nav>

  <nav class="nav2">
    <img src="@/assets/tans_logo.png" class="img_logo">
    <div class="icon_menu" @click="toggleMenu">

      <svg t="1665109375117" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
        p-id="7941" width="50" height="50">
        <path
          d="M195.584 245.76h571.392A31.66208 31.66208 0 0 1 798.72 277.36064v15.79008a31.66208 31.66208 0 0 1-31.744 31.60064H195.584A31.66208 31.66208 0 0 1 163.84 293.15072v-15.79008A31.66208 31.66208 0 0 1 195.584 245.76z m0 236.97408H608.256a31.66208 31.66208 0 0 1 31.744 31.60064v15.79008a31.66208 31.66208 0 0 1-31.744 31.60064H195.584A31.66208 31.66208 0 0 1 163.84 530.14528v-15.81056a31.66208 31.66208 0 0 1 31.744-31.60064z m0 236.97408h571.392A31.68256 31.68256 0 0 1 798.72 751.32928v15.81056A31.66208 31.66208 0 0 1 766.976 798.72H195.584A31.66208 31.66208 0 0 1 163.84 767.11936v-15.79008a31.66208 31.66208 0 0 1 31.744-31.60064z"
          p-id="7942" fill="#005e58"></path>
      </svg>
      menu
    </div>
    <ul class="menu" >
      <li @click="toggleMenu">
        <router-link to="/">首页</router-link></li>
      <li @click="toggleMenu"><router-link to="/productmenu">产品介绍</router-link></li>
      <li @click="toggleMenu"><router-link to="/service">服务</router-link></li>
      <li @click="toggleMenu"><router-link to="/aboutus">关于我们</router-link></li>
      <li @click="toggleMenu"><router-link to="/news">新闻</router-link></li>
    </ul>
  </nav>
  <div class="br_header"></div>

  <!-- <div class="br_nav"></div> -->
  <router-view />
</template>
<script>
  export default{
    mounted(){
      document.querySelector('.menu').style.display='none'

    },
    methods:{
toggleMenu(){
    let menu = document.querySelector('.menu')
    let br= document.querySelector('.br_header')
    console.log(menu.style)
    menu.style.display!='none'?menu.style.display='none':menu.style.display='block'
    br.style.display=='none'?br.style.display='block':br.style.display='none'
  }
    }
  }
  

</script>
<style>
  @font-face {
      font-family: "阿里巴巴普惠体 2.0 115 Black";
      font-weight: 1500;
      src: url("//at.alicdn.com/wf/webfont/1NZSLWoLtGBN/UesiHPRIb24jremtktS4D.woff2") format("woff2"),
        url("//at.alicdn.com/wf/webfont/1NZSLWoLtGBN/-NFfDHWrPzaJbumssivKf.woff") format("woff");
      font-display: swap;
    }
body {
  margin: 0
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
@media screen and (min-width:905px) {
  .nav2{
    display: none;
  }
  nav a {
  text-align: center;
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}

nav a:hover {
  font-size: larger;
  color: #02605f;
  text-shadow: 2px 1px 2px #d4d4d4;

}
}

.img_logo {
  height: 50px;
  margin: 0 80px;
}

nav {
  display: flex;
  align-items: center;
  padding: 15px;
  font-size: large;
}

nav div {
  width: 100px;
  position: relative;
}



nav a:hover .underline_hover {
  height: 3px;
  width: 10px;
  position: absolute;
  left: 50%;
  margin-top: 5px;
  transform: translateX(-50%);
  background-color: #02605f;
  border-radius: 2px;
}

.br_nav {
  height: 2px;
  background-color: #00a9a6;
}

.br_header {
  height: 15px;
  width: 80%;
  border-radius: 0 0 60px 0;
  background-color: #002794;
  position: absolute;
  top: 75px;
  z-index: 99;
}

@media screen and (max-width:905px) {
  .nav1 {
    display: none;
  }

  .nav2 {
    justify-content: space-between
  }

  .img_logo {
    height: 50px;
    margin: 0 20px;
  }

  .icon_menu {
    color: #02605f;

    font-weight: 600;

    height: 50px;
    width: 50px;
    line-height: 5px;
  }
  .menu{
    display: none;
    position: absolute;
    width: 100%;
    top:60px;
    background-color: #02605f;
    z-index: 99;
    left: 0;
    list-style-type: none;
    color: white;
    padding: 0;
  }

  .menu li{
    height: 45px;
    font-size: 20px;
    line-height: 40px;
    border-bottom: 1px solid rgb(192, 192, 192);
    text-indent: 1em;
  }

  a{
    display: inline-block;
    width: 100%;
    height: 100%;
    color:white;
    text-decoration: none;
  }
}
</style>

import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView/HomeView.vue'
import ProductMenu from '../views/ProductMenu/MainView.vue'
import AboutUs from '../views/AboutUs/MainView.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/service',
    name: 'service',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MyService/MainView.vue')
  },

  {
    path: '/productmenu',
    name: 'productmenu',
    component:ProductMenu,
  },
  {
    path:'/prod',
    component:()=>import ('../views/ProductDetail/ProductDetail.vue'),
    children:[{
      path:'zt1962s',
      component:()=>import('@/views/ProductDetail/machines/ZT1962S.vue')
    },{
      path:'hd8916',
      component:()=>import ('../views/ProductDetail/machines/DoubleBottomer.vue')
    },{
      path:'singlebottomer',
      component:()=>import ('../views/ProductDetail/machines/SingleBottomer.vue')
    },{
      path:'flattuber',
      component:()=>import ('../views/ProductDetail/machines/FlatTuber.vue')
    },{
      path:'say1300b4',
      component:()=>import ('../views/ProductDetail/machines/SAY1300B4.vue')
    },{
      path:'say1300c4',
      component:()=>import ('../views/ProductDetail/machines/SAY1300C4.vue')
    },{
      path:'say2100b4',
      component:()=>import ('../views/ProductDetail/machines/SAY2100B4.vue')
    },{
      path:'say1300b6',
      component:()=>import ('../views/ProductDetail/machines/SAY1300B6.vue')
    },
    {
      path:'hotmelt',
      component:()=>import ('../views/ProductDetail/packages/HotMelt.vue')
    },
    {
      path:'supersound',
      component:()=>import ('../views/ProductDetail/packages/SuperSound.vue')
    },
  ]
  },
  // {
  //   path: '/tubemachine',
  //   name: 'tubemachine',
  //   component:TubeMachine
  // },
  {
    path:'/aboutus',
    name:'aboutus',
    component:AboutUs
  },
  {
    path:'/news',
    name:'news',
    component:()=>import('@/views/NewsView/MainView.vue')
  },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

<template>
    <div class="outter">
        <div class="title_con">我们的优势</div>
        <!-- <div style="position: relative;margin-bottom: 600px;box-sizing: content-box;"> -->
        <div class="con_edge">
            <div class="item_edge one">
                <img src="@/assets/patents.jpg" />
                <div class="description des_one">
                    <div class="title_item">行业领先的专利数量</div>
                    <div class="cont_item">我们拥有包括自动扯断、翻底、点胶和双材、双阀、双补等核心科技在内的60多个专利技术，确保公司的行业竞争优势。</div>
                </div>
            </div>
            <div class="item_edge two">
                <img src="@/assets/hightech_corp_certificate.png" />
                <div class="description des_2">
                    <div class="title_item">高新技术企业</div>
                    <div class="cont_item">新明峰旗下达肯公司拥有雄厚的技术和研发实力，被评为“广东省知识产权示范企业”，同时被认定为“高新技术企业”</div>
                </div>
            </div>
            <div class="item_edge three">
                <img src="@/assets/cycle.png" />
                <div class="description des_3">
                    <div class="title_item3">全产业链企业</div>
                    <div class="cont_item3">上下游联动，战略协同，更强大的能力，更可靠的服务，更稳定的品质</div>
                </div>
            </div>
            <div class="item_edge four">
                <img src="@/assets/map.png" />
                <div class="description des_3">
                    <div class="title_item3">全球服务</div>
                    <div class="cont_item3">我们的纸袋业务和机械业务拥有大量国内和海外客户，产品与服务受到海内外客户的一致认可</div>
                </div>
            </div>
        </div>
        <!-- </div> -->
    </div>
</template>
<script>
export default {
    methods: {

    }
}
</script>
<style scoped>
.con_edge {
    display: grid;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    grid-template-columns: repeat(4, 250px);
    grid-gap: 20px;
    grid-template-rows: repeat(2, 250px);
}

.one {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
}

.two {
    grid-column-start: 3;
    grid-column-end: 5;
}

.description {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    color: #333;
    background-image: linear-gradient(120deg, rgba(255, 255, 255, 0.407), #bde9e7);
    transition: opacity 0.4s;
}

.outter {
    position: relative;
    height: 800px;
    background-color: #f1f1f1;
}
.title_item {
    font-size: 28px;
    line-height: 70px;
    font-weight: 550;
}

.cont_item {
    font-size: 16px;
    line-height: 28px;
    font-weight: 550;
}
.item_edge {
    position: relative;
    background-color: antiquewhite;
    box-shadow: 2px 2px 6px 2px #3333;
}

.item_edge img {
    width: 100%;
    height: 100%;
    position: absolute;
}





.description:hover {
    opacity: 1;
}



.des_one {
    padding: 40% 10% 30% 40%
}



.title_item3 {
    font-size: 22px;
    font-weight: 550;
}

.des_3 {
    padding: 40% 5% 30% 20%
}


.cont_item3 {
    font-size: small;
    margin-top: 20px;
    font-weight: 550;
    line-height: 1.2rem;
}

.des_2 {
    padding: 10% 5% 30% 20%
}

.title_item2 {
    font-size: 28px;
    line-height: 70px;
    font-weight: 550;
}

.cont_item2 {
    font-size: 16px;
    line-height: 1.2rem;
    font-weight: 550;
}
@media screen and (max-width:600px) {
    .con_edge {
        width: 100%;
        left: 0;
        transform: none;
        grid-template-columns: 50% 50%;
        grid-template-rows: 100vw 50vw 50vw;
        grid-gap: 0;
    }

    .one {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    }

    .two {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .description {
        opacity: 0.9;
        width: 100%;
        height: 100%;
        position: absolute;
        box-sizing: border-box;
        bottom: 0;
        color: #333;
        background-image: linear-gradient(120deg, rgba(255, 255, 255, 0.144), #cdeceb);
        transition: opacity 0.4s;
    }
    .outter{
        height: calc(230vw);
        
    }
    .title_item,.title_item2,.title_item3{
        font-size: 4.5vw;
        line-height: 4.5vw;
    }

    .cont_item,.cont_item2,.cont_item3{
        font-size: 3.2vw;
        margin-top: 2.8vw;
    }

}


</style>
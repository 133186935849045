<template>
    <div class="bg_machine">

        <div class="title_con">包装机械<div class="decor"></div>
        </div>
        <div class="con_machine">
            <div class="item_machine" v-for="item in machine_list" :key="item.img_url"
                @click="$router.push('prod/'+item.url)">
                <img :src="item.img_url" />
                <div class="br_mac"></div>
                <div class="machine_name">{{item.name}}</div>
            </div>
        </div>
    </div>

    <div class="bg_machine bg_bag">
        <div class="title_con">纸袋包装<div class="decor"></div>
        </div>
        <div class="con_machine">
            <div class="item_machine" v-for="item in bag_list" :key="item.img_url"
                @click="$router.push('prod/'+item.url)">
                <img class="img_bag" :src="item.img_url"
                    style="width: 50%;margin-top: 3%;height: 57%;margin-left:31%;" />
                <div class="br_mac"></div>
                <div class="machine_name">{{item.name}}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            machine_list: [
                {
                    img_url: require('@/assets/machine/ZT1962S.png'),
                    name: 'ZT1692S伺服制筒机',
                    url: 'zt1962s'
                },
                {
                    img_url: require('@/assets/machine/HD8916.jpg'),
                    name: 'HD8916双边糊底机',
                    url: 'hd8916'
                }, {
                    img_url: require('@/assets/machine/trans_tube.png'),
                    name: 'YR2019平切伺服制筒机',
                    url: 'flattuber'
                }, {
                    img_url: require('@/assets/machine/FM2018.jpg'),
                    name: 'FM2018单边糊底机',
                    url: 'singlebottomer'
                }, {
                    img_url: require('@/assets/machine/SAY1300B4.png'),
                    name: 'SAY1300B4木纹纸凹版印刷机',
                    url: 'say1300b4'
                },
                {
                    img_url: require('@/assets/machine/SAY1300C4.png'),
                    name: 'SAY1300C4木纹纸凹版印刷机',
                    url: 'say1300c4'
                },
                {
                    img_url: require('@/assets/machine/SAY2100B4.png'),
                    name: 'SAY2100B4 PVC凹版印刷机',
                    url: 'say2100b4'
                },
                {
                    img_url: require('@/assets/machine/SAY1300B6.png'),
                    name: 'SAY1300B6宝丽纸凹版印刷机',
                    url: 'say1300b6'
                },
            ],
            bag_list: [
                // { img_url: require("@/assets/bag/valvebag.png"), name: '方底阀口袋', url: '' }, 
                { img_url: require("@/assets/bag/ultrasoundbag.png"), name: '超声波阀口袋', url: 'supersound' }, 
                { img_url: require("@/assets/bag/hotmeltbag.png"), name: '热熔阀口袋', url: 'hotmelt' }
            ]
        }
    }
}
</script>
<style scoped>
.bg_machine {
    background-color: #f3f3f3;
    padding: 60px 10%;
    
}

.title_con {
    text-align: left;
    font-size: 30px;
    line-height: 40px;
    height: 70px;
    margin-left: -40px;
    margin-top: 20px;
    color: black;
}

.decor {
    width: 140px;
    height: 6px;
    border-radius: 3px;
    background-color: #002794;
}

.con_machine {
    display: flex;

    /* max-height: 600px; */
    flex-wrap: wrap;
}

.item_machine {
    position: relative;
    height: 268px;
    width: 260.5px;
    border-radius: 5px;
    background-color: white;
    border: 2px solid rgb(212, 212, 212);
    margin-bottom: 30px;
    margin-right: 30px;
}

.item_machine:hover {
    box-shadow: 0 0 8px 2px #cacaca;
}

.item_machine img {
    width: 70%;
    height: 50%;
    margin-left: 15%;
    margin-top: 10%;
}

.machine_name {
    height: 40%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    /* line-height: 90px; */
    border-radius: 0 0 5px 5px;
    /* background-color: #002794; */
    color: black;
    font-size: larger;
    font-weight: 550;
}

.br_mac {
    width: 50px;
    height: 8px;
    border-radius: 2px;
    background-color: #002794;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translateX(-50%);
}

.bg_bag {
    background-color: white
}
</style>
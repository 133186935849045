<template>
  <div class="home">
    <el-carousel height="600px" >
      <el-carousel-item>
        <img src="@/assets/dark_peak.jpg"/>
        <!-- <img src="@/assets/slogen.png"/> -->
        <div class="slogen">勇攀高峰，包装世界</div>
      </el-carousel-item>
    </el-carousel>
    <ProdBox></ProdBox>
    <EdgeBox></EdgeBox>
    <NewsBox></NewsBox>
    <AboutUs></AboutUs>
    <BottomBox></BottomBox>
  </div>
</template>

<script>
// @ is an alias to /src
import {  ElCarousel } from 'element-plus';
import ProdBox from './ProdBox.vue'
import EdgeBox from './EdgeBox.vue'
import NewsBox from './NewsBox.vue'
import AboutUs from './AboutUs.vue'
import BottomBox from './BottomBox.vue';

export default {
  name:'HomeView',
  components: {
    
    ElCarousel,
    ProdBox,
    EdgeBox,
    NewsBox,
    AboutUs,
    BottomBox
},

}
</script>
<style>


.title_con {
  text-align: center;
  font-size: xx-large;
  font-weight: 550;
  line-height: 130px;

}
.el-carousel img{

width: 100%;
height: 99%;
}

@media screen and (min-width:600px) {
  .slogen{
    width: 450px;
  }
}
@media screen and (max-width:600px) {
  .slogen{
    width: 266px;
  }
}
.slogen{
  position: absolute;
  top:50%;
  color:white;
  text-align: center;
  left:50%;
  transform: translate(-50%,-50%);
  font-size: 50px;
}
</style>
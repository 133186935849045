<template>
    <div>
        <img src="@/assets/ynk_real.jpg" class="banner" />
        <div class="bg">
            <div class="title">
                关于我们
                <div class="decor_line"></div>
            </div>
            <div class="con_cont">
                <div class="cont">

                    <p style="text-indent: 2rem;">
                        新明峰是全球领先的纸袋包装生产设备供应商和纸袋供应商。在将近40年的历史中，我们一直在不懈探索，
                        致力于生产出更加坚固、耐用、环保、低成本的纸袋，和性能优越、质量稳定可靠、资源节约的纸袋生产设备。
                    </p>
                    <p style="text-indent: 2rem;">
                        作为一家全产业链企业，新明峰向客户提供一站式服务：从专业咨询，到定制设备，到现场组装生产线，
                        我们提供完整的解决方案。纸袋业务我们提供设计和生产服务
                    </p>
                    <div><text style="font-weight:550">新明峰企业理念：</text>
                        <div>
                            <div class="dot"></div>创新：开拓进取，从不停止向更先进的方向迈进.
                        </div>
                        <div>
                            <div class="dot"></div>真诚：努力赢取、从不辜负客户信任，与客户保持紧密沟通和信息透明。
                        </div>
                        <div>
                            <div class="dot"></div>可持续：机械设备质量稳定可靠，节约资源，终身售后服务，纸袋坚固、环境友好。
                        </div>
                    </div>
                </div>
                <div class="logos">
                    <img src="@/assets/tans_logo.png" />
                    <img src="@/assets/unicorn_all.png" />
                    <img style="width:60%;margin-left:30px" src="@/assets/trans_tecon.png" />
                </div>
            </div>
        </div>
        <bottom-box></bottom-box>
    </div>
</template>
<script>
import BottomBox from './../HomeView/BottomBox.vue'

export default {
    components: { BottomBox },
}
</script>
<style scoped>
.bg {
    padding: 108px 22%;
    background-color: white;
}

.title {
    text-align: left;
    font-size: 40px;
    line-height: 70px;
}

.decor_line {
    width: 40px;
    height: 6px;
    background-color: #002794;
    border-radius: 2px;
}

.con_cont {
    display: flex;
    margin-top: 30px
}

.cont {
    width: 50%;
    font-size: 18px;
    line-height: 1.8rem;
}

.logos {
    width: 50%;
    padding-left: 60px;
}

.logos img {
    width: 80%;
    margin-top: 20px;
}

.dot {
    display: inline-block;
    margin-bottom: 0.2rem;
    margin-right: 0.4rem;
    height: 6px;
    width: 6px;
    background-color: #002794;
    border-radius: 100%;

}

.banner {
    width: 100%;
    height: 500px;
}

@media screen and (max-width:600px) {
    .con_cont{
        display: block;
    }
    .bg{
        padding:20px;
    }
    .cont{
        box-sizing: border-box;
        width: 100%;
        padding:0 20px;
    }

    .logos{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-left: 20px;
    }

    .banner{
        width: 100vw;
        height: auto;
    }
}
</style>
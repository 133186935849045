<template>
    <div class="con_aboutus">
        <div class="title_con">关于我们</div>
        <div class="content">广东新明峰包装科技有限公司，成立于1984年，总部位于广东佛山，是从事纸袋包装生产设备及纸袋设计、研发、生产和销售的完整产业链供应商，致力于为包装纸袋生产企业和化工、建材、食品行业企业提供全面、专业的包装解决方案。
            <el-button class="btn_more" @click="jumpToAboutUs">查看更多</el-button>
        </div>

    </div>
</template>
<script>
    
    import{ElButton}from 'element-plus'
    export default{
        components:{
            ElButton
        },
        methods:{
            jumpToAboutUs(){
                this.$router.push('/aboutus');
                document.documentElement.scrollTop=650
            }
        }
    }
</script>
<style scoped>
    .con_aboutus{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #159c95;
        padding-bottom: 60px;
    }
    .title_con{
        color: white;
        line-height: 80px;
        font-size: 25px;
    }
    .content{
        width: 700px;
        background-color: #58cec6;
        padding: 20px 30px;
        border-radius: 10px;
        color: white;
        font-size: larger;
        line-height: 1.8rem;
    }
    .btn_more{
    background-color: #2f50ff;
    color: white;
    border:#2550ff;
    font-weight: 500;
    margin-left: 150px;
    float: right;
    margin-top: 40px;
}
@media screen and (max-width:600px) {
    .content{
        width: 100%;
        box-sizing: border-box;
    }
}
</style>
<template>
    <div>
        <!-- <div class="con_logos">
            <img src="@/assets/unicorn_all.png"/>
            <img src="@/assets/trans_tecon.png"/>
        </div> -->
        <div class="con_media">
            <div class="intro">
                新明峰是全球领先的纸袋包装生产设备供应商和纸袋供应商，贯彻创新、真诚、可持续的企业理念，致力于为世界带来更加高效耐用环保的产品
            </div>
            <div class="connection">
                <div class="title_item">联系我们</div>
                <div class="info">广东省 佛山市 顺德区 大良街道 顺番公路五沙段17号之8A</div>
                <div class="info">电话：86-13326728126</div>
                <div class="info">邮箱：info@newtecon.com</div>
            </div>
            <div class="nav">
                <router-link to="/">首页</router-link>
                <router-link to="productmenu">产品列表</router-link>
                <router-link to="service">我们的服务</router-link>
                <router-link to="aboutus">关于我们</router-link>
                <router-link to="news">新闻</router-link>
            </div>
            <div class="media">
                <div style="font-size: large; text-align: left;margin-bottom: 20px;">新明峰在线</div>
                <!-- <img src="@/assets/icon/email.png" />
                <img src="@/assets/icon/wechat.png" /> -->
                <img src="@/assets/icon/youtube.png" @click="cantjump" />
                <img src="@/assets/icon/facebook.png" @click="cantjump" />
            </div>
        </div>
        <div class="con_copyright">
            © Copyright 2022 Newmingfeng. All Rights Reserved
        </div>


    </div>
</template>
<script>
import { ElMessage } from 'element-plus'
export default {
    methods: {
        cantjump() {
            ElMessage({ message: '抱歉，此功能暂未开放', type: 'warning' })
        }
    }
}
</script>
<style scoped>
.con_logos {

    padding: 15px 150px;
    display: flex;
    justify-content: space-evenly;
}

.con_logos img {
    height: 60px;
}

.con_media {
    height: 160px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    font-weight: 550;
    flex-wrap: wrap;
    background-color: #ebe9e9;
    padding: 50px 15% 20px 12%;
}

.intro {
    width: 25%;
    text-align: left;
    line-height: 26px;
    font-weight: 400;

}

.connection {
    text-align: left;
}

.title_item {
    text-align: left;
    font-size: large;
    margin-bottom: 20px;
}

.con_media img {
    height: 40px;
    margin-right: 15px;
}

.con_copyright {
    height: 40px;
    text-align: center;
    line-height: 40px;
    background-color: #c2c2c2;
    color: #f9f9f9;
}

.info {
    height: 30px;
    font-weight: 400;
}

.nav a {
    display: block;
    text-decoration: none;
    height: 32px;
    width: 150px;
    font-weight: 500;
    color: #2c3e50;
}

.nav a:hover {
    text-decoration: underline;
}

@media screen and (max-width:600px) {
    .con_media {
        display: flex;
        height: auto;
        /* flex-wrap: wrap-reverse; */
    }

    .intro{
        display: none;
    }
    .info{
        height: auto;
    }

    .nav{
        margin-top: 30px;
        display: flex;
        width: 50%;
        flex-wrap: wrap;
    }

    .nav a{
        width: auto;
        padding:0 10px;
        height: 20px;
        line-height: 20px;
    }
    .media{
        margin-top: 30px;
    }
}
</style>